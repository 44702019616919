@font-face {
  font-family:'Exo 2';
  font-style: normal;
  font-weight: 400;
  src:
    local("Exo 2"),
    local("Exo2"),
    url("../fonts/exo2-regular.eot?") format("embedded-opentype"),
    url("../fonts/Exo2-Regular.ttf") format("truetype");
}

@font-face {
  font-family:'Exo 2';
  font-style: normal;
  font-weight: 700;
  src:
    local("Exo 2 Bold"),
    local("Exo2-Bold"),
    url("../fonts/exo2-bold.eot?") format("embedded-opentype"),
    url("../fonts/Exo2-Bold.ttf") format("truetype");
}

@font-face {
  font-family:'rouble';
  font-style: normal;
  font-weight: 700;
  src:
          url("../fonts/rouble.ttf") format("truetype");
}
