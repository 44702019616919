.banks {
  margin-bottom: 30px;

  &__wrapper {
    max-width: 85%;
    margin: auto;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  &__title {
    font-size: @mobile-size-title;
    line-height:35px;
    text-align: center;
    margin-bottom: 15px;
  }
  &__description {
    font-family: "Arial", sans-serif;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    max-width: 790px;
    margin: auto;
    margin-bottom: 20px;
  }
  &__item {
    max-width: 160px;
    margin: 20px 20px;
    transition: 0.2s;
    filter: grayscale(100%);
    opacity: 0.5;
    &:hover {
      filter: grayscale(0%);
      opacity: 1;
    }
  }
}
@media @mobile-widths-only {
  .banks{
    &__item{
      max-width: 90px;
    }
  }
}

@media @tablet-widths {
  .banks {
    margin-bottom: 40px;
    &__item {
      max-width: 160px;
    }
    &__list {
      justify-content: space-between;
    }
  }
}

@media @desktop-widths {
  .banks {
    margin-bottom: 39px;

    &__wrapper {
      max-width: 1080px;
      margin: auto;
    }
    &__title{
      font-size: @desktop-size-title;
      line-height:45px;
    }
  }
}