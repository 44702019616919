.district {
  padding-top: 30px;
  background-color: #F4F4F4;
  &__wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 0;
  }
  &__title {
    text-align: center;
    margin-bottom: 35px;
    font-size: @mobile-size-title;
    line-height: 1.45;
  }
  &__map {
    width: 100%;
    height: 320px;
    background-color: #2e3133;
    margin-top: 30px;
    position: relative;
  }
  &__item {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    box-sizing: border-box;
    position: relative;
  }
  &__list {
    max-width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.district-item {
  &__img {
    display: block;
    border: 8px solid #fff;
    border-radius: 50%;
    margin-right: 15px;
  }
  &__title {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 13px;
    margin-top: 4px;
  }
  &__description {
    text-align: center;
    line-height: 24px;
  }
}

.map__btn-call {
  position: absolute;
  background-color: #ff5926;
  color: #fff;
  bottom: 55px;
  left: 8px;
  width: 42%;

}

.map__btn-more-houses {
  display: block;
  padding: 10px 0;
  border: 0;
  font-size: 16px;
  text-align: center;
  position: absolute;
  background-color: #fff;
  color: #000;
  bottom: 55px;
  right: 15px;
  width: 38%;
  &:hover{
    color: #fff;
  }
}

#map {
  height: 100%;
}

@media @mobile-widths-only {
  .district {
    &__title {
      max-width: 80%;
      margin: auto;
    }
    &__item{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: 210px;
    }
    &-item{
      &__img{
        width: 35%;
        margin-bottom: 40px;
        margin-right:0;
      }
      &__title{
        width: 52%;
      }
      &__description{
        width: 52%;
        font-size: 15px;
        line-height: 1.45;
      }
      &__button-block{
        position: absolute;
        bottom: 0;
        width: 80%;
        margin:  auto;
      }
    }
  }
}

@media @tablet-widths {
  .district-item {
    &__description {
      text-align: center;
      margin-top: 14px;
    }
    &__button-block {
      display: none;
      position: absolute;
      background: #fff;
      left: 0;
      bottom: -61px;
      padding: 10px 0 20px;
      width: 100%;
      text-align: right;
      z-index: 5;
    }

  }

  .district {
    &__item {
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      &:hover {
        box-shadow: 0 3px 20px rgba(0, 0, 0, .1);
        background-color: #fff;
        & .district-item__button-block {
          display: block;
          box-shadow: 0 20px 20px rgba(0, 0, 0, .1);
          border-radius: 0 0 3px 3px;
          border-top: none;
        }
      }
    }
    &__btn-call {
      margin: auto;
      width: 90%;
    }
  }

  .map__btn-call {
    bottom: 46px;
    left: 25px;
    width: 42%;
  }

  .map__btn-more-houses {
    bottom: 46px;
    right: 50px;
    width: 38%;
  }

}

@media @desktop-widths {
  .district {
    padding-top: 65px;
    background-color: #F4F4F4;
    &__btn-call {
      margin-left: auto;
      display: block;
      padding: 7px 0;
      width: 180px;
      background-color: #ff5926;
      border: 0;
      color: #fff;
      margin-bottom: 6px;
      font-size: 14px;
      text-align: center;
      transition: .2s;
      margin-right: 15px;
    }
    &__wrapper {
      max-width: 1200px;
      margin: 0 auto;
      padding-bottom: 82px;
    }
    &__title {
      text-align: center;
      margin-bottom: 58px;
      font-size: @desktop-size-title;
    }
    &__map {
      width: 100%;
      height: 568px;
      background-color: #2e3133;
      margin-top: 25px;
      position: relative;
    }
    &__item {
      width: 32%;
      padding: 24px 10px;
      display: block;
      position: relative;
    }
    &__list {
      max-width: 1100px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .district-item {

    &__img {
      float: left;
      display: block;
      border: 8px solid #fff;
      border-radius: 50%;
      margin-right: 15px;
    }
    &__title {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 13px;
      margin-top: 4px;
    }
    &__description {
      line-height: 22px;
      text-align: left;
      font-size: 14px;
      margin-top: 14px;
    }
    &__button-block {
      display: none;
      position: absolute;
      background: #fff;
      left: 0;
      bottom: -41px;
      padding: 10px 25px 20px 10px;
      width: 100%;
      box-sizing: border-box;
      text-align: right;
      z-index: 5;
    }
  }

  .map__btn-call {
    position: absolute;
    bottom: 43px;
    left: 242px;
    background-color: #ff5926;
    width: 31%;
    color: #fff;
  }

  .map__btn-more-houses {
    display: block;
    padding: 10px 0;
    width: 31%;
    border: 0;
    font-size: 16px;
    text-align: center;
    position: absolute;
    bottom: 43px;
    right: 181px;
    background-color: #fff;
    color: #000;
  }
}


