body {
  font-family: "Exo 2", "Arial", sans-serif;
  background-color: #fff;
  color: #000000;
}

h1 {
  font-size: 50px;
  line-height: 30px;
  color: #231f20;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

h2 {
  font-size: 40px;
  line-height: 40px;
  color: #000000;
  font-weight: bold;
  margin: 0;
}

h3 {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  line-height: 20px;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}
p{
  margin: 0;
}

//@media @mobile-widths-only{
//  h1{
//    font-size: 25px;
//  }
//}

.btn-call {
  display: block;
  padding: 10px 0;
  width: 208px;
  background-color: @red-color;
  border: none;
  color: #fff;
  margin-bottom:6px;
  font-size: 16px;
  font-weight:bold;
  text-align: center;
  transition: 0.2s;
  &:hover{
    background-color: #e30c1e;
  }
}


[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}