@red-color: #ff5926;
@bg-footer: #f1f1f1;
@mobile-size-title:25px;
@tablet-size-title:35px;
@desktop-size-title:40px;
@form-text-color: #d2d2d2;
@border-color: #ececec;
@border-hover: #62d1ba;
@border-active: #c7c7c7;
@color-text-hover: #8d8d8d;
@color-icon-hover: #46c1ae;
@color-icon-active: #bde3dd;
@tablet-widths: ~"(min-width: 768px)";
@desktop-widths: ~"(min-width: 1200px)";
@mobile-widths-only: ~"(max-width: 767px)";

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
