.call-back {
  padding-top: 30px;
  background-color: #F4F4F4;
  &__wrapper {
    max-width: 80%;
    margin: auto;
  }
  &__title {
    text-align: center;
    margin-bottom: 18px;
    font-size: 25px;
    line-height: 35px;
  }
  &__description {
    text-align: center;
    max-width: 875px;
    margin: auto;
    margin-bottom: 41px;
    font-size: 18px;
    font-family: "Arial", sans-serif;
    line-height: 27px;
  }
}

.user-info {
  display: flex;
  flex-wrap: wrap;
  &__name {
    width: 100%;

    margin-bottom: 10px;
  }
  &__email {
    width: 100%;

    margin-bottom: 10px;
  }
}

.form {
  &__user-email,
  &__user-name {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #cecfcf;
    color: #989999;
  }
  &__btn-submit {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    color: #fff;
    background-color: #FF5926;
    border: none;
    font-size: 21px;
    font-weight: bold;
    &:hover{
      background-color: #e30c1e;
    }
  }
  &__consent {
    color: #AAAAAA;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    & a {
      color: #AAAAAA;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      &:hover {
        color: #555;
        border-bottom: 1px solid rgba(0, 0, 0, 0.8);
      }
    }
  }
}

@media @tablet-widths {
  .form {
    &__btn-submit {
      font-size: 16px;
      width: 30%;
    }
  }
  .user-info {
    display: flex;
    flex-wrap: wrap;
    &__name {
      width: 30%;
      margin-right: 19px;
      margin-bottom: 0;
    }
    &__email {
      width: 30%;
      margin-right: 19px;
      margin-bottom: 0;
    }

  }

}

@media @desktop-widths {
  .call-back {
    padding-top: 65px;
    &__wrapper {
      max-width: 1200px;
      margin: auto;
    }
    &__title {
      font-size: 40px;
      line-height: 40px;
    }
  }

  .form {
    &__btn-submit {
      font-size: 21px;
    }
    &__title {
      text-align: center;
      margin-bottom: 18px;
      font-size: @desktop-size-title;
      line-height: 45px;
    }
    &__consent{
      padding: 35px 0;
      margin-bottom: 7px;
    }
    &__user-email,
    &__user-name {
      padding: 20px;
    }
  }
  .user-info__email{
    margin-right: 14px;
  }
}