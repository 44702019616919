.popup {
  width: 450px;
  background-color: #F4F4F4;
  padding: 30px;
  &--opened {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2000;
    transform: translate(-50%, -50%);
  }
  &--closed {
    display: none;
  }

  &-overlay {
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__title {
    margin-bottom: 15px;
  }
  &__subtitle {
    margin-bottom: 15px;
    color: #696f6f;
  }
  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  &__item {
    width: 100%;
    padding: 15px 0;
    &--button {
      width: 40%;
      box-sizing: border-box;
      margin-right: 30px;
    }
    &--consent {
      width: 40%;
      box-sizing: border-box;
    }
  }
  &-item__value {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 2px solid #ebebeb;
    &:focus {
      border: 2px solid #8f8f8f;
    }
  }
  &__form-consent a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  &-send-form {
    width: 100%;
    box-sizing: border-box;
    background: #FF5926;
    border: none;
    border-radius: 3px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #e30c1e;
    }
  }
}

fieldset {
  margin: 0;
  border: none;
  padding: 0;
}

.text-center .gr{
  padding: 50px;
  text-align: center;
  font-size: 27px;
}

@media @mobile-widths-only {
  .popup {
    width: 231px;
  }
}
